
.editor  {
  padding-left: 17px;
  height: auto !important;
}

.scrollTags {
  max-height: 110px !important;
  overflow: auto;
}
