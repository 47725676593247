.container {
  --workflow-stage-accent-text-colour: #6a7388;
  --workflow-stage-accent-background-colour: #e6e7eb;
  --workflow-stage-border-colour: #cdd0d7;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 28px 83px;
  background-color: #fff;
  border: 1px solid var(--workflow-stage-border-colour);
  border-radius: 12px;
}

.containerEditing {
  composes: container;

  --workflow-stage-accent-text-colour: #fff;
  --workflow-stage-accent-background-colour: #1e5eff;
  --workflow-stage-border-colour: #1e5eff;
}

.containerComplete {
  composes: container;

  --workflow-stage-accent-text-colour: #fff;
  --workflow-stage-accent-background-colour: #15a251;
}

.indicator {
  position: absolute;
  top: 25px;
  left: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: var(--workflow-stage-accent-text-colour);
  background-color: var(--workflow-stage-accent-background-colour);

  // hide icon unless stage is complete
  svg {
    display: none;
  }

  .containerComplete & {
    // hide the nested ui-library Text component
    // when stage is complete..
    span {
      display: none;
    }

    // ..and show the icon instead
    svg {
      display: block;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 24px;
  word-break: break-all;
}

.disabledIcon,
.actionMenu {
  position: absolute;
  right: 37px;
}

.editIcon {
  width: 16px;
  height: 16px;
}
