/* #right-panel.command-params-visible.lums-view {
  height: calc(75vw);
}
 */
.command-params-visible .lums-view {
  height: 25vh;
}

.lums-view .MuiGrid-container {
  align-items: center;
}

.lums-view .section {
  padding: 12px;
  border-top: 1px solid lightgrey;
}

.lums-view .section-info {
  font-size: 90%;
  margin-bottom: 12px;
  margin-left: 12px;
}

.lums-view .section-info.blue {
  color: #1976d2;
}

.lums-view .input-combo {
  margin: 12px;
}

.lums-view .input-label {
  font-weight: bold;
  line-height: 50px;
  margin-right: 24px;
}

.lums-view .input-label-top {
  font-weight: bold;
}

.lums-view .input {
  background-color: white;
}

.lums-view .input .MuiInputBase-root {
  font-size: 100%;
}

.lums-view .select-container {
  position: relative;
}

.lums-view .select-input {
  background-color: white;
}

.lums-view .select-input .MuiInputBase-root {
  font-size: 100%;
}

.lums-view .chips-container {
  position: relative;
  display: inline-block;
  min-width: 10vw;
}

.lums-view .chips-input {
  background-color: white;
  height: 50px;
  padding-left: 8px;
}

.lums-view .chip {
  margin: 4px;
}

.lums-view .chip .MuiChip-label {
  font-size: 150%;
}

.lums-view .select-list {
  position: absolute !important;
  top: 33px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.lums-view .select-list-2 {
  position: absolute !important;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.lums-view .chips-list {
  position: absolute !important;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.lums-view .icon {
  height: 50px;
  margin: 0 12px;
}

.lums-view .header-selector-button.normal {
  background-color: rgb(249, 249, 247);
  color: #0000008a;
}

.lums-view .header-selector-button.inverted {
  background-color: #0000008a;
  color: rgb(249, 249, 247);
}

.lums-view .option {
  margin: 8px;
}

.lums-view .long {
  width: 16wv;
}

.lums-view .action {
  width: 120px;
  margin: 4px;
}

.lums-view .right {
  text-align: right;
}
