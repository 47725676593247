.container {
  display: flex;

  dt,
  dd {
    // remove browser default margin
    margin: 0;
  }

  dt {
    margin-right: 8px;
    padding-bottom: 4px;
  }
}
