.selectedMethod {
  background: #ffffff;
  border: 1px solid #1e5eff;
  box-sizing: border-box;
  border-radius: 12px;
}

.unSelectedMethod {
  background: #ffffff;
  border: 1px solid #cdd0d7;
  box-sizing: border-box;
  border-radius: 12px;
}

.disabledMethod {
  opacity: 0.5;
  background: #ffffff;
  border: 1px solid #cdd0d7;
  box-sizing: border-box;
  border-radius: 12px;
}
