.container {
  display: flex;
  padding: 32px 64px 32px 32px;
}

.description {
  flex: 1;
  word-break: break-all;
  white-space: normal;
}
