.circle {
  border: 1px solid #CDD0D7;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link{
  text-decoration: none;
}

.downloadOriginalFile{
  color:#0d6efd;
  cursor: pointer;
}