.statusIcon {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 15px;
}

.pending {
  background-color: #fbc066;
}

.renderCell {
  display: inline-flex;
}