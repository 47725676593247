.borderTop {
  border-top: 1px solid #e6e7eb;
}

.item {
  display: flex;
  margin-bottom: 4px;

  dt,
  dd {
    // remove browser default margin
    margin: 0;
  }
}

.labels {
  min-width: 180px;
}

.leftIndent {
  margin-left: 180px;
}
