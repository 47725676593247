.footerMenu {
  position: fixed;
  bottom: 0;
  background-color: #2c3957;
  padding-bottom: 20px;
  z-index: 1000;
  top: 88% !important;
}

.footerMenuDivider {
  background: #2c3957;
  border-top: 1px solid #6a7388;
  padding-bottom: 20px;
}

.menuSpacing {
  margin-top: 76px;
  margin-bottom: 120px;
  background-color: #2c3957;
}

.sideBarLink {
  text-decoration: none;
}