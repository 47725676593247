//All styles belonging to ModalHeader.js are added in here.

.iconCloseRemove {
  cursor: pointer;
  color: #6a7388;
}

.verticalDividerSpan {
  padding: 0px 1rem;
  color: #e0e0e0;
}

.contentAreaLeft {
  padding: 0 20px;
}

.contentAreaRight {
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  margin-left: auto;
  padding-left: 20px;
}

.contentCenter {
  flex: 1;
  display: flex;
  justify-content: center;
}

.footer {
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: -32px;
  background-color: #f7f8f9;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 32px;
  position: fixed;
  bottom: 32px;
  width: 100%;
}
