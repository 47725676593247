
ul.result-list {
    list-style-type: none;
    padding-left: 0; /* Remove padding */
    // margin: 0; /* Remove margins */
}

.result-failure {
    color: #f0142f;    
}
  

.link {
    text-decoration: none;
  } 
   