.activeBackground {
  background-color: #f4f7ff !important;
}

.activeText {
  color: #1e5eff !important;
}

.cursorPointer {
  cursor: pointer;
}

.checkIconSize {
  width: 18px;
  height: 18px;
}

.disabledItem {
  color: #9ca2b0;
}

.outputMappingList {
  height: 70vh;
  overflow: auto;
  border-right: 1px solid #e6e8eb;
}

.outputMappingDisplay {
  width: 66%;
  float: right;
}

.outputMappingPreview {
  border: 1px solid #cdd0d7;
  border-radius: 4px;
  display: flex;
}

.outputMappingList::-webkit-scrollbar {
  width: 8px;
}

.outputMappingList::-webkit-scrollbar-track {
  border-radius: 10px;
}

.outputMappingList::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cdd0d7;
  min-height: 10px !important;
  cursor: pointer;
}

.bottomBorder {
  border-bottom: 1px solid #cdd0d7;
}

.noDividerHeader {
  border-right: none;
}

/* MyGrid.module.css */
.customGrid {
  --ag-header-column-resize: none;
}

.tableHeader {
  background-color: #f2f3f5;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-top: 8px;
  padding-right: 12px;
  width: 100%;
  border-bottom: 1px solid #e6e8eb;
}

.tableBody {
  max-height: 50vh;
  overflow: auto;
  font-size: 14px;
}

.tableWrapper {
  border: 1px solid #e6e8eb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tableRow {
  border: 1px solid #e6e8eb;
  width: fit-content;
  padding: 8px;
}
.tableColIndex {
  min-width: 70px;
  text-align: center;
  border: 1px solid #e6e8eb;
  padding: 8px;
}

.columnName {
  width: 100%;
}

.tableBody::-webkit-scrollbar {
  width: 8px;
}

.tableBody::-webkit-scrollbar-track {
  border-radius: 10px;
}

.tableBody::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cdd0d7;
  min-height: 10px !important;
  cursor: pointer;
}

.centeredSpinner {
  position: absolute;
  left: 66%;
  top: 50%;
}
