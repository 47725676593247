html {
  box-sizing: "border-box";
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/* Required to ensure TBUI backgrond and fonts are styled correctly*/
#TemplateBuilderApp {
  font-size: 12px;
  line-height: 1.42857;
  color: rgb(85, 85, 85);
  /*   border-style: solid;
  border-width: thin;
  border-color: #aeadab; */
  /* margin-bottom: 144px; */
}

/* Required to ensure TBUI modals appear above the full-screen modal in which it is embedded*/
.MuiDialog-root {
  z-index: 5500 !important;
  background: none !important;
}

#appFrame {
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  background-color: #edece7;
}

#submitApproveButtonsContainer {
  /* margin-left: -32px; */
  margin-right: -32px;
  margin-bottom: -32px;
  background-color: #f7f8f9;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 32px;
  position: fixed;
  bottom: 32px;
  width: 100%;
}

.robotoFont {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.formInput {
  margin-bottom: 10px;
}

.toolbar {
  height: 64px;
}
.mainView {
  display: inline-flex;
  height: 100%;
}

.templateMapping-view {
  overflow-x: hidden;
}

.command-stack-view {
  /*-moz-transition: width 100ms ease-out;
    -webkit-transition: width 100ms ease-out;
    transition: width 100ms ease-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -moz-animation-direction: alternate;
    overflow-x:auto;*/
}

.command-params-root {
  width: 100%;
  height: 0px;
  overflow-y: auto;
  background-color: #f9f9f7;
}

.command-params-visible .command-params-root {
  height: 25vh;
  max-height: auto !important;
  overflow-x: hidden;
}

.command-params-visible .data-view {
  min-width: 500px;
}

.header-clickable th {
  cursor: pointer;
  background-color: #0000008a;
  color: white;
  border-right: 1px solid grey;
}

.header-clickable th:hover {
  background-color: white;
  color: #0000008a;
}

.header-selector-button {
  min-width: 150px;
}

/* Styling used on raised buttons */
button.frButtonStyle {
  background-color: #f54828;
  color: #ffffff;
  font-size: 9px;
  border-radius: 8px;
  margin: 5px;
}
/* Style for panel to display a S&T selection */
div.selectionStyle {
  border-style: inset;
  background-color: #e8e6e3;
  border-radius: 8px;
  padding: 5px;
  margin: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
}

span.sourceDestinationLabel {
  font-size: smaller;
  font-weight: 500;
}

p.templateTitle {
  font-weight: 500;
  font-size: 22px;
}

div.commandListItemPadding {
  padding-top: 3px;
  padding-bottom: 0px;
}

.cellWithEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*################### Panels #################*/

div.large-panel,
ul.large-panel {
  background-color: #edece7;
  height: auto;
  margin-right: 10px;
}

#left-panel {
  width: 15vw;
}

#right-panel {
  width: auto;
}

/*########### Data table ########*/

.data-view {
  background-color: white;
  height: 89vh !important;
  overflow-y: auto;
}

.infoArray {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.infoArray button {
  margin: 10px;
}

.unstructured-table textarea {
  overflow-x: auto;
  height: 77vh;
  width: 100%;
}

/*############# Colours ################*/

.icon-colour {
  color: #0000008a;
}

.warning-icon-colour {
  color: orange;
}

div.background-white,
.background-white {
  background-color: white;
}

/*############# Command Stack and Mapper List items ##############*/

div.listItemPaper {
  width: 100%;
  padding: 0px;
  box-shadow: none;
}

div.listItemPaper:hover {
  background-color: #e9e9e9;
}

.mapper-item-text {
  padding: 6px;
  max-width: 200px;
  min-width: 50px;
  font-weight: 100;
}

div.mapper-text-input {
  background-color: #fafafa;
  border-radius: 2px;
  border-width: 2px;
  border-style: inset;
  padding: 3px;
}

.vertical-align-middle {
  vertical-align: middle;
}

/*######### Select and Transform ##############*/

.selectAndTransformDescriptionText {
  border-top: 1px solid lightgrey;
  margin: 0px 10px;
  line-height: 1.8;
  letter-spacing: 0.5px;
}

.selectAndTransformListView {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 115px;
}

.selectAndTransformTextInput {
  margin-top: 32px;
  border: 1px inset;
  border-radius: 3px;
  padding: 2px;
  width: 100%;
}

button.selectAndTransformSubmitButtonEnabled {
  border: 1px solid silver;
  color: lightgreen;
  animation: flash 2s 1;
  background-color: white;
}

/*############### Buttons ################*/

button.MuiButtonBase-root-97 {
  width: 30px;
  height: 30px;
}

button.paramsButton,
.paramsButton {
  pointer-events: auto;
  color: #0000008a;
  box-shadow: 2px 2px 5px grey;
  background-color: #f9f9f7;
  height: 40px;
  width: 40px;
}

button.submitParamsButton,
.submitParamsButton {
  margin-top: 25px;
}

.MuiIcon-root-100,
span.MuiIcon-root-100 {
  font-size: 20px;
}

/*############### Submit Approve Reject Buttons #################*/

button.submit-approve-reject-buttons {
  pointer-events: auto;
  position: fixed;
  bottom: 50px;
  box-shadow: 4px 8px 10px grey;
  height: 40px;
  width: 40px;
}

.right-5pc {
  right: 5%;
}

.right-10pc {
  right: 10%;
}

/*############# Toolbar ##############*/
#tb-toolbar {
  background-color: #edece7;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 3px;
}

button.toolbar-nav-buttons {
  pointer-events: auto;
}

/*########### Spinner ###############*/

#spinner {
  color: #f54828;
  margin-top: 5px;
}

/*#################### Font ######################*/

.font-500,
div.font-500,
span.font-500 {
  font-weight: 500;
}

.font-100,
div.font-100,
span.font-100 {
  font-weight: 100;
}

.font-600,
div.font-600,
span.font-600,
p.font-600 {
  font-weight: 600;
}

.font-small,
div.font-small,
span.font-small p.font-small {
  font-size: small;
}

.font-medium,
div.font-medium,
span.font-medium p.font-medium {
  font-size: medium;
}

.font-size-large,
span.font-size-large {
  font-size: large;
}

.text-align-centre {
  text-align: center;
}

.align-right {
  text-align: right;
}

/*######### Padding, Margin & Border ################*/

.padding-5,
div.padding-5,
span.padding-5 {
  padding: 5px;
}

div.padding-2,
.padding-2 {
  padding: 2px;
}

.padding-6 {
  padding: 6px;
}

li.padding-left-0,
.padding-left-0 {
  padding-left: 0px;
}

div.padding-0,
li.padding-0,
.padding-0 {
  padding: 0px;
}

div.padding-top-0,
.padding-top-0 {
  padding-top: 0px;
}

h1.padding-top-10,
.padding-top-10 {
  padding-top: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

div.padding-12,
h1.padding-12,
.padding-12 {
  padding: 12px;
}

.padding-right-12 {
  padding-right: 12px;
}

li.padding-15,
.padding-15 {
  padding: 15px;
}

h1.padding-left-50,
.padding-left-50 {
  padding-left: 50px;
}

.margin-top-6,
div.margin-top-6 {
  margin-top: 6px;
}

.margin-top-22,
button.margin-top-22 {
  margin-top: 22px;
}

.margin-top-20,
input.margin-top-20 {
  margin-top: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

p.margin-right-10,
.margin-right-10 {
  margin-right: 10px;
}

button.margin-right-15,
.margin-right-15 {
  margin-right: 15px;
}

button.margin-right-minus-6,
.margin-right-minus-6 {
  margin-right: -6px;
}

button.margin-right-minus-5,
.margin-right-minus-5 {
  margin-right: -5px;
}

button.margin-right-0,
.margin-right-0 {
  margin-right: 0px;
}

button.margin-bottom-15,
.margin-bottom-15 {
  margin-bottom: 15px;
}

p.margin-left-10,
.margin-left-10 {
  margin-left: 10px;
}

.margin-top-20,
button.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-0,
p.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-bottom-20,
button.margin-bottom-20 {
  margin-bottom: 20px;
}

span.margin-right-35,
.margin-right-35 {
  margin-right: 35px;
}

.border-bottom-grey {
  border-bottom: 1px solid #cacaca;
}

/*######### Widths & Heights ################*/

.max-width-65-pc {
  max-width: 65%;
}

.max-width-100-pc {
  max-width: 100%;
}

.width-800,
div.width-800 {
  width: 800px;
}

/*#################### React-Autosuggest #################*/
/* Taken from React-autosuggest docs: https://codepen.io/moroshko/pen/LGNJMy */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  margin-left: 5px;
  margin-top: -17px;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 265px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 20000000;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.tmo-editor-green {
  color: rgb(0, 204, 0);
}
.tmo-editor-paper {
  width: 100%;
  box-shadow: none !important;
  padding: 0px;
}

.tmo-editor-paper:hover {
  background-color: #e9e9e9;
}

.tmo-editor-text,
.tmo-editor-text input {
  font-size: 1.25rem !important;
}

.tmo-editor-table tr {
  padding: 0;
}

.tmo-editor-table th {
  border-width: 0 0 2px 0;
  border-style: solid gray;
}

.tmo-editor-table td {
  padding: 0 0 0 24px;
  border-width: 1px 0 0 0;
  border-style: solid gray;
}

.marginLeft12 {
  margin-left: 12px;
}

.marginBottom9 {
  margin-bottom: 9px;
}
