.circle {
  border: 1px solid #cdd0d7;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.betaStatus {
  background-color: #f54828;
  position: absolute;
  top: 0;
  z-index: 10;
  right: 80px;
  border-radius: 21px;
  padding: 4px;
  color: white;
  font-size: 11px;
  margin-top: 6px;
}

.aiSummaryButton {
  position: relative;
}

.verticalDivider {
  width: 1px;
  background-color: #cdd0d7;
  height: 24px;
  margin-left: 12px;
  margin-right: 12px;
  align-self: center;
}
