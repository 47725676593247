.container {
    display: flex;
    padding: 20px 25px;
    
    // match style of ui-library Button component
    border: 1px solid #cdd0d7;
    border-radius: 0.25rem;
    background: none;
    transition: all 0.15s ease-in-out;
    
    &:hover,
    &:active {
        border-color: #1E5EFF;
    }
    
    &:active {
        background-color: #e3e9f7;
    }

    &:disabled {
        color: inherit;
        border-color: #cdd0d7;
        background-color: #F7F8F9;
    }
    
    // add spacing between multiple buttons
    & + & {
        margin-left: 12px;
    }
}

.icon {
    width: 12px;
    margin-top: 3px;
    margin-right: 12px;
    color: #4A5568;
}

.text {
    display: flex;
    flex-direction: column;
}