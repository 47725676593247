.command-params-input {
  width: 30%;
  max-width: 380px;
  display: inline-block;
}
.command-params-input-field {
  width: 30%;
  max-width: 380px;
  display: inline-block;
}

.command-params-input .command-params-input-field {
  margin: 10px 10px 5px 10px;
}

.command-params-input .command-params-input-group {
  margin: 5px 5px 5px 5px;
}

.command-params-input .command-params-column-select {
  margin: 0px 0px 0px 0px;
}

.command-params-root {
  height: 100%;
}

.command-params-zero-top {
  padding-top: 0;
}

.update-button {
  margin-left: 35%;
}

.command-params-title {
  padding-left: 10px;
  padding-top: 10px;
}

.react-autosuggest__input {
  width: 90%;
  max-width: 400px;
  height: 30px;
  padding: 10px 10px;
  font-weight: 300;
  font-size: 16px;
  margin: 5px;
}

 input[aria-controls="react-autowhatever-react-autosuggest--AI Assistant"]{
  width:55vw !important;
  max-width: 55vw;
}

div.command-params-root {
  background-color: #f9f9f7;
}

.command-params-container {
  width: 90%;
  margin: auto;
}

button.dropdownButton {
  width: 200px;
  text-transform: inherit;
  font-size: 1.1rem;
  color: #696969;
}
