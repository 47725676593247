.select-transform-container {
  margin: 10px 10px 10px 10px;
}

.fullWidth > div {
  width: 100%;
}

.icons-stacked button {
  width: 18px;
  height: 18px;
  margin-bottom: 5px;
}

.icons-across {
  display: flex;
  flex-direction: row;
}

#header-index-picker {
  min-width: 100px;
}
.items {
  padding: 16px 16px 0 0;
}
.items span {
  text-overflow: ellipsis;
}
.items > div {
  margin-left: 15px;
}

.save-button,
button.save-button {
  padding: 5px;
  min-height: 40px;
  min-width: 40px;
}

.grid-container {
  background-color: #f9f9f7;
}

button.icon-button {
  color: #ffffff;
  background-color: #f54828;
  width: 30px;
  max-height: 30px;
  min-height: 30px;
}
