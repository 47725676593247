.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-left: 24px;
  margin-top: 8px;
}

.item {
  display: flex;
  margin-bottom: 4px;

  dt,
  dd {
    // remove browser default margin
    margin: 0;
  }
}

.labels {
  min-width: 180px;
}
