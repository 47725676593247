.outputContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 24px;
  margin-right: 24px;
}

.outputSectionBottomBorder {
  border-bottom: 1px solid #e6e8eb;
  padding-bottom: 12px;
}

.noFlex {
  flex: none;
}

.splitIconPointer {
  cursor: pointer;
}

.badgeCounter {
  color: #ffffff;
  background-color: #1e5eff;
  border-radius: 15px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 10px;
  margin-left: 8px;
  align-self: center;
}
