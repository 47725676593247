.item {
  display: flex;
  margin-bottom: 4px;

  dt,
  dd {
    // remove browser default margin
    margin: 0;
  }
}

.labels {
  min-width: 180px;
}

.badgeCounter {
  color: #ffffff;
  background-color: #1e5eff;
  border-radius: 15px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 10px;
  margin-left: 8px;
  align-self: center;
}
