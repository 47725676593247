// non-modular global styles here

//Added global value for ag-grid cell height
.ag-cell-edit-wrapper {
  height: 42px !important;
}

// disable zebra striping of ag-grid rows
.ag-theme-alpine .ag-row-odd {
  background-color: #fff;
}

// Fix height for editing row
.ag-theme-alpine .ag-cell-inline-editing {
  height: auto !important;
}

// Remove default min-height of 150px
.ag-center-cols-clipper {
  min-height: unset !important;
}
