.dropdownList {
  max-height: 280px !important;
  overflow-y: auto !important;
}

.modalFooter {
  border-top: 0rem;
  border-radius: 0px 0px 12px 12px;
  background-color: #f7f8f9;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.linkStyle {
  color: white;
}
.linkStyle:hover {
  color: white;
}
