$stages-vertical-spacing: 24px;

.container {
  // stretch container to fill screen
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f7f8f9;
}

.headerWrapper {
  padding: 2rem;
  background-color: #fff;
  border-bottom: 1px solid #e6e7eb;
}

.stages {
  display: flex;
  flex-direction: column;
  margin: 48px auto;
  max-width: 1000px;

  // apply vertical spacing between child components
  > * + * {
    margin-top: $stages-vertical-spacing;

    // add connecting line between stages
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 50%;
      // add a pixel to compensate for the Stage component's border
      // when positioning - this will place the line starting
      // from the top edge of the Stage component
      bottom: calc(100% + 1px);
      width: 1px;
      height: $stages-vertical-spacing;
      background-color: #cdd0d7;
    }
  }
}
