.textField {
  min-width: 320px;
  margin: 8px;
}
.flexContainer {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: flex-start;
}
.checkbox {
  margin: 10px;
}

.checkboxForm {
  margin-top: 5px;
}

.chipStyle {
  margin: 10px;
}

.rectangle {
  min-height: 250px;
  height: auto;
  gap: 20px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px dashed var(--primary-150-t, #9ca2b0);
  background: var(--primary-0-t, #fff);
}

.rectangle > * {
  margin-right: 8px;
  margin-top: 8px;
}

.dragAndDrop {
  width: 57px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 30px;
}

.tags {
  margin-top: 4px;
}

.tagsButton {
  display: inline-block;
}

.tags > * {
  margin-right: 8px;
  margin-top: 8px;
}

.actions {
  margin-top: 24px;
  text-align: left;
  margin-bottom: 32px;
}

.actions > * {
  margin-right: 32px;
}

.customTagInput {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border: none;
  outline: none;
  align-self: center;
  height: 27px;
}

.customTagText {
  background-color: #e9efff;
  align-self: center;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  height: 28px;
}

.firstCellSelect {
  min-width: 230px;
  border: 1px solid var(--Tertiary-50-T, #e9efff);
  outline: none;
}
