.statusBody {
  display: inline-block;
  min-width: 80px;
  padding: 4px 0;
  line-height: 18px;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
}

.approved {
  background-color: #e8f6ee;
  color: #15a251;
}

.published {
  background-color: #e8f6ee;
  color: #15a251;
}

.completed,
.success {
  background-color: #e8f6ee;
  color: #15a251;
}

.rejected {
  background-color: #fee8ea;
  color: #f0142f;
}

.failed,
.failure {
  background-color: #fee8ea;
  color: #f0142f;
}

.in_progress,
.pending {
  background-color: #fef5e6;
  color: #f99600;
}

.draft {
  background-color: #e6e8eb;
  color: #6a7388;
}
