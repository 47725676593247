.breakSpace {
  white-space: break-spaces;
}

.statusIcon {
  display: inline-flex;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 15px;
}
.pending {
  background-color: #fbc066;
}

.approved {
  background-color: #15a251;
}

.draft {
  background-color: #6a7388;
}