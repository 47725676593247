.structure-table {
  overflow-x: auto;
  height: 100%;
}

#structured-data-table {
  white-space: nowrap;
  border-collapse: collapse;
}

#structured-data-table-header {
  background-color: #fcfcfc;
}

.structured-data-table-header-cell {
  font-weight: 600;
  padding: 10px;
  padding-bottom: 8px;
  border: 1px solid #dddddd;
}

.structured-table-cell {
  padding: 3px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  background-color: white;
}

th,
td {
  border: 1px solid #dddddd;
}
