.iconDownArrow {
    width: 20px;
    height: 20px;
    color: #6a7388;
    cursor: pointer;
}

.iconRightArrow {
    width: 20px;
    height: 20px;
    transform: scale(0.8);
    color: #6a7388;
    cursor: pointer;
}

.iconDownArrowDisabled {
    width: 20px;
    height: 20px;
    color: #b5b9c4;
    cursor: not-allowed;
    opacity: 0.7;
}

.iconSpaceLeft {
    margin-left: 4px;
}

.editCommand {
    width: 20px;
    height: 20px;
    color: #6a7388;
    margin-right: 12px;
    cursor: pointer;
}

.cloneCommand {
    width: 20px;
    height: 20px;
    color: #6a7388;
    margin-right: 12px;
    cursor: pointer;
}

.deleteCommand {
    width: 20px !important;
    height: 20px !important;
    color: #6a7388;
    transform: scale(1.1);
    margin-top: 2px;
    cursor: pointer;
}

.editCommand {
    width: 20px;
    height: 20px;
    color: #6a7388;
    margin-right: 12px;
    cursor: pointer;
}

.disabledEditCommand {
    color: #b5b9c4;
    opacity: 0.4;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    cursor: not-allowed;
}

.disabledCloneCommand {
    color: #b5b9c4;
    opacity: 0.7;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    cursor: not-allowed;
}

.disabledDeleteCommand {
    color: #b5b9c4;
    opacity: 0.7;
    width: 20px;
    height: 20px;
    transform: scale(1.1);
    margin-top: 2px;
    cursor: not-allowed;
}

.triggerSectionPaddingTop {
    padding-top: 12px;
    padding-bottom: 12px;
}

.triggerSectionTopBorder {
    border-top: 1px solid #e6e8eb;
    border-bottom: 1px solid #e6e8eb;
}

.triggerSectionBottomBorder {
    border-bottom: 1px solid #e6e8eb;
}