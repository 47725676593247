.tag {
  background: #f2f3f5;
  border-radius: 4px;

  padding-left: 8px;
  padding-right: 8px;

  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  word-break: break-all !important;
}

.close {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.cursorPointer {
  cursor: pointer;
}