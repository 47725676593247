.aIIcon {
  background-color: #feedea;
  display: flex;
  padding: 0px 4px;
  align-items: center;
  border-radius: 4px;
  color: #f54828;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
}

.orderedList {
  padding-top: 32px;
  overflow-x: none;
  overflow-y: scroll;
  max-height: 94vh;
  scrollbar-color: #cdd0d7;
  padding-right: 12px;
}

.modalIconsContainer {
  color: #6a7388;
  cursor: pointer;
}

.modalSection {
  width: -webkit-fill-available;
}
