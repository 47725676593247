.disabled {
  cursor: auto !important;
  color: #b4b9c3;
}

.inactive {
  color: #6a7388;
}

.active {
  color: #071639;
}

.note {
  padding: 16px;
  border-radius: 4px;
  background-color: #eff0f3;
  margin-top: 32px !important;
  margin-bottom: 32px;
}
