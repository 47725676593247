.inactiveInput {
    opacity: 0.4;
}

.ruleSectionBottomBorder {
    border-bottom: 1px solid #e6e8eb;
    padding-bottom: 12px;
}  

.columnsContainer {
    border: 1px solid #cdd0d7;
    border-radius: 4px;
    margin-top: 24px;
    max-height: 300px;
    overflow-y: scroll;
}

.containerHeader {
    background-color: #f7f8f9;
    border-bottom: 1px solid #cdd0d7;
    display: flex;
    padding: 12px;
}

.verticalDividerSpan {
    padding: 0px 8px;
    color: #e0e0e0;
  }