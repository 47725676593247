.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
  border-radius: 0.25rem;
  background-color: #f7f8f9;
}

.containerInputEditView {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.25rem;
}

.containerWrapper {
  padding: 4px 20px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.actionButton {
  background: none;
  border: none;
  word-break: keep-all;
}

.removeButtonPadding {
  padding-right: 0px !important;
}
