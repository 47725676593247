.container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  img {
    margin-right: 12px;
  }

  p {
    margin-bottom: 0;
  }
}
