.footer {
  margin-bottom: -32px;
  background-color: #f7f8f9;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 32px;
  position: fixed;
  bottom: 32px;
  width: 100%;
}
