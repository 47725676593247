.ruleStyle {
  background-color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px !important;
  padding-right: 0px !important;
}

.cursorPointer {
  cursor: pointer;
}

.noFlex {
  flex: none;
}

.borderbottom {
  border-bottom: 1px solid #e6e7eb;
}

.disabledRemoveTemplateIcon {
  color: #b5b9c4;
}
