.triggerContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 24px;
    margin-right: 24px;
}

.triggerSectionBottomBorder {
    border-bottom: 1px solid #e6e8eb;
    padding-bottom: 12px;
}