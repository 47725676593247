.fileUploader {
}
.fileUploaderCard {
  padding-top: 80px;
  flex: 1 1 100%;
  max-width: 900px;
  margin: 0 auto;
  -moz-transition: left 500ms ease;
  -webkit-transition: left 500ms ease;
  transition: left 500ms ease;
}
