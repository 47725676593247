.container {
  // unset default button styling
  all: unset;
  cursor: pointer;
}

.refreshIcon {
  color: #4a5568;
}

.refreshIconAnimated {
  composes: refreshIcon;
  color: #6a7388;
  animation-name: rotateAntiClockwise;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateAntiClockwise {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
