.spinner {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: -0.125em;
  border: 0.25em solid red;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border-animation 0.75s linear infinite ;
  margin-left: 10px;
  margin-right: 10px;
}

@keyframes spinner-border-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}