.downloadContainer {
    all: unset;
    cursor: pointer;
    margin-left: 20px;
  }
  
  .downloadIcon {
    color: #4a5568;
    width: 24px;
    height: 24px;
  }